<template>
    <v-card class="card-shadow">
        <v-container>
            <v-row>
                <v-col cols="12" md="8" lg="8">
                    <v-card-title>
                        <span><slot name="title"></slot></span>
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <slot name="text"></slot>
                    </v-card-text>
                    <v-card-text>
                        <slot name="additional"></slot>
                    </v-card-text>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-img :src="`https://bonsai.demoing.co.za/assets/images/${src}`"
                           class="white--text align-end"
                           cover
                           max-height="200"></v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
    export default {
        name: 'service-top-card',
        components: {},
        props: {
            src: String
        },
        data: () => {
            return {
            };
        },
    };
</script>