<template>
    <div>
        <service-top-card src="services_business_top.jpg">
            <template v-slot:title>
                <span>Bonsai for businesses</span>
            </template>
            <template v-slot:text>
                The global business landscape is changing - are your business and its financial adviser
                changing with it? At Bonsai, your business and its employees get access to the Bonsai platform
                free of charge, meaning that you can provide your business with the modernized financial advisory
                support it deserves without any additional cost.
            </template>
            <template v-slot:additional>
                <v-btn color="primary" large class="mr-2">Get Started</v-btn>
            </template>
        </service-top-card>
        <v-row>
            <v-col cols="12" md="6">
                <statements-card src="services_business_statement_1.jpg">
                    <template v-slot:title>
                        Business insurance
                    </template>
                    <template v-slot:text>
                        Protect your business against commercial business interruptions, costs of property
                        damage, theft and claims made against your business by others. You can take out cover against
                        natural disasters, accidental losses, cyber, wilful damage and costs of employees failing in their
                        duties
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card src="services_business_statement_2.jpg">
                    <template v-slot:title>
                        Employee risk and retirement
                    </template>
                    <template v-slot:text>
                        Our financial advisers can assist you with risk and retirement solutions that encourage
                        responsible financial behaviour amongst your employees. By offering employee benefits, you can
                        enable employees to manage life�s economic uncertainties better.
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card src="services_business_statement_3.jpg">
                    <template v-slot:title>
                        Employee healthcare
                    </template>
                    <template v-slot:text>
                        Healthcare benefits help your employees to protect their most valuable asset � their
                        health. We help you reduce the probability and impact of health-related events and lower your and
                        your employees' financial exposure to these events. By offering these benefits, you minimise the
                        effects of health-related events on your business.
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card src="services_business_statement_4.jpg">
                    <template v-slot:title>
                        Rewards
                    </template>
                    <template v-slot:text>
                        Employee members get access to our free rewards programme, meaning that they get
                        rewarded for managing their financial lives on our state-of-the-art platform. Furthermore, help your
                        employees get a monthly cashback, spendable using their Bonsai debit card by working with us.
                    </template>
                </statements-card>
            </v-col>
        </v-row>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-row>
                    <v-col cols="12" md="12" lg="8">
                        <v-card-title> How do we deliver our services? </v-card-title>
                        <v-card-text class="pb-0">
                            Bonsai combines accredited FAIS agents with cutting-edge technology to help you gain
                            control of your finances and plan for the future. Our platform provides a hybrid advisor assisted
                            and self-service access to your portfolio - meaning you get the best of both worlds and engage on
                            your own terms.
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12" lg="4">
                        <v-img src="https://bonsai.demoing.co.za/assets/images/services_business_how.jpg"
                               class="white--text align-end"
                               cover></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-row>
                    <v-col>
                        <v-card-title> braap </v-card-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-carousel cycle continuous height="200">
                            <v-carousel-item v-for="(color, i) in colors" :key="color">
                                <v-sheet height="100%">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="text-h2">Slide {{ i + 1 }}</div>
                                    </v-row>
                                </v-sheet>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import ServiceTopCard from './services/service-top-card.vue'
    import StatementsCard from './home/statements-card.vue'
    export default {
        name: "Business",

        components: {
            ServiceTopCard,
            StatementsCard
        },
        data: () => {
            return {
                tab1: 0,
                tab2: 0,
                items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
                colors: [
                    "red",
                    "green",
                    "blue",
                    "purple",
                    "yellow",
                    "pink",
                    "orange",
                    "amber",
                ],
            };
        },
    };
</script>
