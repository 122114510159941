<template>
    <v-hover v-slot="{ hover }">
        <v-card v-bind:class="['card-shadow', ' overflow-hidden', 'fill-height']" style="cursor:pointer">
            <v-container>
                <v-card-title class="pb-0">
                    <slot name="title" v-bind:hover="hover"></slot><v-spacer></v-spacer>
                </v-card-title>
                <v-row align="center">
                    <v-col>
                        <v-card-text>
                            <slot name="text"></slot>
                        </v-card-text>
                    </v-col>
                    <v-col cols="auto">
                        <lord-icon v:bind="$attrs" :src="`https://cdn.lordicon.com/${icon}.json`"
                                   :trigger="hover ? 'loop' : ''"
                                   :colors="`primary:#121331,secondary:${$vuetify.theme.themes.light.primary}`"
                                   stroke="20"
                                   style="width:100px;height:100px">
                        </lord-icon>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-hover>
</template>
<script>
    export default {
        name: 'statement-card',
        components: {},
        props: {
            icon: String
        },
        data: () => {
            return {
            };
        },
    };
</script>
<style scoped>
    * {
        transition: .5s
    }
</style>